@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?ntjh7t');
  src:  url('../fonts/icomoon.eot?ntjh7t#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?ntjh7t') format('truetype'),
    url('../fonts/icomoon.woff?ntjh7t') format('woff'),
    url('../fonts/icomoon.svg?ntjh7t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none; 
  line-height: 1;
 
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-abc:before {
  content: "\e900";
}
.icon-bag:before {
  content: "\e901";
}
.icon-book:before {
  content: "\e902";
}

// custom icons 


.icon-search{
  background-image: url('../img/icons/search.svg');
  width: 24px;
  height: 24px;
  display: block;
  background-size: contain;
  background-repeat: no-repeat;
}
